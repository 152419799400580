import '@/assets/css/global.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import VueMask from '@devindex/vue-mask'; 

import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

const app = createApp(App);
//app.component("CampaignView", CampaignView)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.use(VueMask)
app.use(Notifications)
app.mount('#app')
