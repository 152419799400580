<template>
    <div class="container w-100 d-flex flex-column justify-content-center pb-4 mt-5">
        <a href="/">
            <img class="logo" src="@/assets/logo.png" alt="GM2 - Treinamento e Consultoria" width="250" /></a>


        <img class="img" src="../assets/notFound.png" alt="notFound" />
        <h1>Oops, parece que a página que você está buscando não existe.</h1>

    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.logo {
    width: 25%;
    margin: auto;
}

.img {
    width: 10%;
}
</style>