import { createRouter, createWebHashHistory } from 'vue-router'
import SiteView from '@/views/SiteView.vue'
import HomeView from '../views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import UnifacisaSubjectView from '@/views/UnifacisaSubjectView.vue'
import NotFound from '@/views/NotFound.vue'
import VideosView from '@/views/VideosView.vue'
import ArticleView from '@/views/ArticleView.vue'
import AllArticlesView from '@/views/AllArticlesView.vue'
import AllVideosView from '@/views/AllVideosView.vue'

import ControlPanel from '@/views/control-panel/CP-App.vue'
import CPHome from '@/views/control-panel/CP-Home.vue'
import CPArticles from '@/views/control-panel/CP-Articles.vue'
import CPVideos from '@/views/control-panel/CP-Videos.vue'
import CPLogin from '@/views/control-panel/CP-Login.vue'

const routes = [
  {
    path: '/control-panel',
    name: 'Control-Panel',
    component: ControlPanel,
    redirect: { name: 'CPDashboard'},
    children: [
      {
        path: '/control-panel/dashboard',
        name: 'CPDashboard',
        component: CPHome
      },
      {
        path: '/control-panel/articles',
        name: 'CPArticles',
        component: CPArticles
      },
      {
        path: '/control-panel/videos',
        name: 'CPVideos',
        component: CPVideos
      },
      {
        path: '/control-panel/login',
        name: 'CPLogin',
        component: CPLogin
      }
    ]
  },
  {
    path: '/',
    name: 'Site',
    component: SiteView,
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/about',
        name: 'About',
        component: AboutView
      },
      {
        path: '/subjects/:subjectId',
        name: 'Subjects',
        component: UnifacisaSubjectView
      },
      {
        path: '/video/:id',
        name: 'Video',
        component: VideosView
      },
      {
        path: '/article/:id',
        name: 'Article',
        component: ArticleView
      },
      {
        path: '/articles',
        name: 'Articles',
        component: AllArticlesView
      },
      {
        path: '/videos',
        name: 'Videos',
        component: AllVideosView
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
