import service from './axiosService.js'
const uri = `/ContentMedia`

export default {
    async add(media) {
        service.initHeaders()
        return await service.axios.post(`${uri}/add-or-update`, media)
    },
    async getAll(type) {
        service.initHeaders()
        return await service.axios.get(`${uri}/get-all/${type}`)
    },
    async getAllByType(type) {
        return await service.axios.get(`${uri}/get-all-by_type/${type}`)
    },
    async delete(contentMediaId) {
        service.initHeaders()
        return await service.axios.delete(`${uri}/delete/${contentMediaId}`)
    },
    async getMediaById(id) {
        service.initHeaders()
        return await service.axios.get(`${uri}/media/${id}`)
    }
}

