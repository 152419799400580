function formatterdDate(date){
    return (date.toLocaleString('pt-BR')).split('T')[0]
}

export default{
    formatterdDate: formatterdDate,
    ROLES: {
        ADMIN:'ADMIN',
        MEMBER:'MEMBER',
        GUEST:'GUEST'
    }
}