const cacheKey = 'userData'

function setSession(data) {
    if (data != null) {
        localStorage.setItem(cacheKey, JSON.stringify(data))
    } else {
        localStorage.clear()
    }
}

function logout() {
    localStorage.clear()
}

function getSession() {
    const userData = localStorage.getItem(cacheKey)
    if (userData) {
        return JSON.parse(localStorage.getItem(cacheKey))
    }
    return null
}

export default { logout, setSession, getSession }