<template>
    <h1 class="mt-3">Artigos</h1>
    <div class="row mt-3">
        <div class="col-sm-4 mb-4" v-for="item in articles" :key="item.id" style="width: 15rem;">
            <a class="link" :href="item.link" target="_blank">
                <div class="card shadow h-100">
                    <img class="card-img-top" :src="`${appStore.fileUrl}${item.photo}`" :alt="item.title" />
                    <div class="card-body">
                        <h6 class="card-title global-font-style">
                            <router-link :to="'/article/' + item.contentMediaId" class="article-link">
                                {{ item.title }}
                            </router-link>
                        </h6>
                        <div>
                            <router-link :to="'/article/' + item.contentMediaId" class="article-link">
                                <small class="card-text">Ler artigo</small>
                            </router-link>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import contentService from '@/services/contentMedia'
import { useAppStore } from '@/stores/appStore';

const articles = ref([])
const appStore = useAppStore()
const ARTICLE = 0

onMounted(() => {
    getArticles()
})

async function getArticles() {
    await contentService.getAll(ARTICLE)
        .then((response) => {
            if (response.data) {
                articles.value = []
                response.data.forEach(element => {
                    articles.value.push(element)
                });
            }
        }).catch((error) => {
            console.log('AllArticles', error)
        })
}
</script>

<style scoped>
.card-text {
    color: black;
}

.article-link {
    text-decoration: none;
    color: #044fa5
}

.article-link:hover {
    text-decoration: none;
    color: #04224454
}

.link {
    text-decoration: none;
}
</style>