<template lang="">
    <div class="main-banner shadow d-flex p-3 mt-3 pb-md-4 mx-auto align-items-center">
        <img class="photo" src="../assets/eu.png" alt="Genilson Medeiros" />
        <div class="ml-4">
            Olá, meu nome é <b>Genilson Medeiros</b>. Sou Professor e Engenheiro de Software. Gostaria de aprender
            novas tecnologias ou frameworks? Então, aproveite os artigos e cursos disponíveis na plataforma! Mas, se
            você chegou aqui, pois, precisa de ajuda com o seu projeto, então entre em contato comigo a partir das
            minhas redes sociais.
        </div>       
    </div>
</template>
<script setup>

</script>
<style scoped>
.main-banner {
    background-color: #383B7C;
    color: beige;
    border-radius: 10px;
}

.main-banner div {
    margin-left: 10px;
    text-align: justify;
}
</style>