import service from './axiosService.js'
const uri = `/Upload`

export default {
    async file(file) {
        service.initHeaders()
        return await service.axios.post(`${uri}/file`, file)
    }
}

