import axios from 'axios'
import EventBus from '@/conf/EventBus'
import notification from '@/conf/Notification'

axios.defaults.baseURL = "https://gm2.dev.br/api"
//axios.defaults.baseURL = "http://localhost:5233/api"
const cacheKey = 'userData'

function init() {
    const userSession = JSON.parse(localStorage.getItem(cacheKey))
    if (userSession) {
        const userToken = userSession.token
        axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    } else {
       console.log(`User Token not Found - session ${userSession}`)
    }
}

axios.interceptors.request.use((config) => {
    EventBus.$emit('loading', true);
    return config
})

// Response interceptor
axios.interceptors.response.use((response) => {

    EventBus.$emit('loading', false);

    if (!response || response.data.status && (response.data.status !== 200 || response.data.status !== 201)) {

        return Promise.reject(response.data)
    }
    return response
}, (error) => {
    EventBus.$emit('loading', false);
    console.log(error)

    if(error.code == 'ERR_NETWORK'){
        //const router = useRouter()
        //const userStore = useUserStore()
        //userStore.logout()
        //router.push('/')
        notification.error("Error", "Falha de conexão!")
    }

    if (error.status === 204) {
        notification.error("Error", error.detail)
    }
    return Promise.reject(error)
})

export default { axios: axios, initHeaders: init }