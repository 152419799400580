<template>
  <div class="container w-100 d-flex justify-content-center pb-4 mt-5">
    <form>
      <div class="d-flex justify-content-center">
        <a href="/">
          <img src="@/assets/logo.png" alt="GM2 - Treinamento e Consultoria" width="250" /></a>
      </div>
      <!-- Email input -->
      <div data-mdb-input-init class="form-outline mb-4 mt-2">
        <input type="text" v-model="user.username" id="form2Example1" class="form-control" />
        <label class="form-label" for="form2Example1">Login</label>
      </div>

      <!-- Password input -->
      <div data-mdb-input-init class="form-outline mb-4">
        <input type="password" @keyup.enter="login" v-model="user.password" id="form2Example2" class="form-control" />
        <label class="form-label" for="form2Example2">Senha</label>
      </div>

      <!-- 2 column grid layout for inline styling -->
      <div class="row mb-4">
        <div class="col">
          <!-- Simple link -->
          <a href="#!">Esqueci minha senha?</a>
        </div>
      </div>

      <!-- Submit button -->
      <button @click="login" type="button" data-mdb-button-init data-mdb-ripple-init
        class="btn btn-primary w-100 btn-block mb-4">Login</button>

      <!-- Register buttons -->
      <div class="text-center">
        <p>Não é membro? <a href="#!">Registrar</a></p>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import securityManager from '@/services/securityManager'
import authService from '@/services/authService';

const user = ref({ username: '', password: '' })
const router = useRouter()

 function login() {
  authService.authenticate(user.value.username, user.value.password)
    .then((response) => {
      if (response.data) {
        securityManager.setSession(response.data)
        router.push('/control-panel')
      }
    }).catch(() => {
      securityManager.setSession(null)
    })
}
</script>

<style></style>