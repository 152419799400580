<template>
    <div class="container">
        <div class="row">
            <h1>Dashboard - Home</h1>
        </div>
    </div>
</template>

<script setup>

</script>