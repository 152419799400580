<template>
    <div v-if="username" class="container">
        <div class="row">
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                    <router-link to="/control-panel" class="navbar-brand">Panel de controle - GM2</router-link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link to="/control-panel" class="nav-link active" aria-current="page">Dashboard</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/control-panel/articles" class="nav-link active" aria-current="page">Artigos</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/control-panel/videos" class="nav-link active" aria-current="page">Vídeos</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/control-panel" class="nav-link active" aria-current="page">Cursos</router-link>
                            </li>
                            <li class="nav-item">
                              
                            </li>
                        </ul>
                        <div class="w-100 d-flex flex-row-reverse">
                            <span>Bem vindo <b>{{ username }},</b> <router-link to="#"  @click="logout">Sair</router-link></span> 
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <RouterView />
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUpdated, ref } from 'vue'
import { useRouter } from 'vue-router'
import securityManager from '@/services/securityManager'

const router = useRouter()
const username = ref('')

onMounted(() => {
    getSession()
})

onUpdated(()=>{
    getSession()
})

function getSession() {
    const session = securityManager.getSession()
    if(session) {
        username.value = session.name
    } else {
        router.push('/control-panel/login')
    }    
}

function logout() {
    securityManager.logout()
    router.push('/')
}

</script>

<style scoped>
.nav-link:hover{
    font-weight: bolder;
}
</style>