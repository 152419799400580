<template>
    <div>
        <hr>
        <div class="d-flex justify-content-between">
            <h2><b>Artigos</b></h2>
            <button @click="newMedia" class="btn btn-primary">Novo</button>
        </div>
        <hr>
        <section>
            <label class="form-label" for="form2Example1">Foto de capa</label>
            <div data-mdb-input-init class="form-outline mb-4 mt-2 d-flex">
                <div v-if="previewImage">
                    <img :src="previewImage" width="250" height="250" alt="" />
                </div>
                <div v-else>
                    <img v-if="media.photo" :src="`${appStore.fileUrl}${media.photo}`" width="250" height="250" alt="" />        
                    <img v-else :src="`${appStore.fileUrl}no_photo.jpg`" width="250" height="250" alt="" />
                </div>

                <input type="file" @change="uploadFile" class="form-control" />
            </div>
            <label class="form-label" for="form2Example1">Título</label>
            <div data-mdb-input-init class="form-outline mb-4 mt-2">
                <input type="text" v-model="media.title" id="form2Example1" class="form-control" />
            </div>
            <label class="form-label" for="form2Example1">Conteúdo</label>
            <QuillEditor class="editor" v-model:content="media.data" :modules="modules" toolbar="full"
                contentType="html" />
            <div data-mdb-input-init class="form-outline mb-4 mt-2">
                <label class="form-label" for="form2Example1">Tags</label>
                <input type="text" v-model="media.tags" id="form2Example1" class="form-control" />
            </div>
            <div data-mdb-input-init class="form-outline mb-4 mt-2">
                <input type="checkbox" v-model="media.cover" class="form-check-input" id="form2Example1"/>
                <label class="form-check-label ml-2" for="flexCheckDefault">
                    Capa?
                </label>
            </div>
            <div class="d-flex flex-row-reverse">
                <button v-if="media.contentMediaId > 0" @click="removeMedia(media.contentMediaId)"
                    class="w-25 btn btn-danger mt-3">Excluir</button>
                <button @click="saveMedia" class="w-25 btn btn-success mt-3 mr-2">Salvar</button>
            </div>
        </section>
        <hr>
        <h2>Artigos</h2>
        <section>
            <table class="table table-hover shadow p-4 mb-4 bg-white">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Capa</th>
                        <th>Publicado</th>
                        <th>Título</th>
                        <th>Tags</th>
                        <th>Editar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in articles" :key="index">
                        <td>{{ item.contentMediaId }}</td>
                        <td>{{ item.cover }}</td>
                        <td><img :src="item.photo ? `${appStore.fileUrl}${item.photo}` : `${appStore.fileUrl}/no_photo.jpg`" alt="" width="80" height="80" /></td>
                        <td>{{ utils.formatterdDate(item.updated) }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.tags }}</td>
                        <td>
                            <button @click="select(item.contentMediaId)" class="btn btn-primary">Editar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
//Documentation
//https://vueup.github.io/
import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import notification from '@/conf/Notification'
import contentService from '@/services/contentMedia'
import fileUpload from '@/services/uploadService'
import utils from '@/conf/utils'
import { useAppStore } from '@/stores/appStore';

const ARTICLE = 0

const modules = {
    name: 'blotFormatter',
    module: BlotFormatter,
    options: {/* options https://vueup.github.io/vue-quill/guide/modules.html */ }
}

const media = reactive({
    contentMediaId: 0,
    photo: '',
    type: ARTICLE,
    title: '',
    data: '',
    tags: '',
    cover: false
})
let articles = ref([])
let fileSeletionAux = ref(null)

const previewImage = ref('')
const appStore = useAppStore()

onMounted(() => {
    getAllMedias()
})

function uploadFile(event) {
    media.photo = event.target.files[0]
    fileSeletionAux.value = event.target.files[0]
    showPreview(media.photo)
}

function showPreview(photo) {
    let reader = new FileReader();

    reader.onload = (e) => {
        previewImage.value = e.target.result;
    };
    reader.readAsDataURL(photo);
}

async function saveMedia() {
    const thereIsFile = fileSeletionAux.value
    const fd = new FormData()

    if(thereIsFile) {
        fd.append("file", media.photo);
        await fileUpload.file(fd)
            .then((response)=> {
                if (response.status === 200) {
                    media.photo = response.data
                    saveData()
                }
            }).catch((error)=>{
                console.log('FileUpload', error)
                notification.error("Foto", "Não foi possível enviar a foto")
            })
    } else {
        saveData()
    }
}

async function saveData() {
    await contentService.add(media)
        .then((response) => {
            if (response.status === 200) {
                notification.success("Sucesso", "Artigo publicado com sucesso!")
                getAllMedias()
            }
        }).catch((error) => {
            console.log('comp error', error)
            notification.error('Erro', error.message)
        })
}

async function getAllMedias() {
    await contentService.getAll(ARTICLE)
        .then((response) => {
            if (response.data) {
                articles.value = []
                response.data.forEach(element => {
                    articles.value.push(element)
                });
            }
        }).catch((error) => {
            console.log('getAll comp', error)
        })
}

async function removeMedia(contentMediaId) {
    const result = confirm("Deseja remover o artigo?")
    if (result) {
        await contentService.delete(contentMediaId)
            .then((response) => {
                console.log('remove', response)
                cleanEditor()
                getAllMedias()
            }).catch((error) => {
                console.log('removeMedia comp', error)
            })
    }
}

function select(contentMediaId) {
    cleanEditor()
    const articleFinded = articles.value.find(a => a.contentMediaId === contentMediaId)
    media.title = articleFinded.title
    media.data = articleFinded.data
    media.tags = articleFinded.tags
    media.photo = articleFinded.photo
    media.cover = articleFinded.cover ? true : false
    media.contentMediaId = articleFinded.contentMediaId
}

function newMedia(){
    cleanEditor()
}

function cleanEditor() {
    media.title = ''
    media.data = 'Vazio'
    media.tags = ''
    media.photo = ''
    fileSeletionAux.value = null
    media.contentMediaId = 0
    media.cover = false
    previewImage.value = ''
}

</script>

<style>
.editor {
    height: 400px;
}
</style>