import {ref} from 'vue'
import { defineStore } from 'pinia'

//https://pinia.vuejs.org/core-concepts/#using-the-store
export const useAppStore = defineStore('useAppStore', ()=>{
    const isDevelopment = ref(false)
    const fileUrl = ref(isDevelopment.value ? 'http://localhost:5233/download/content-media/' : 'https://gm2.dev.br/download/content-media/')
    
    function isDevelopmentMode() {
        return isDevelopment.value
    }
    return {fileUrl: fileUrl.value, isDevelopmentMode: isDevelopmentMode}
})
