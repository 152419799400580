<template>
    <div class="mt-5">
        <p>Última atualização: {{ utils.formatterdDate(media.updated) }}</p>
        <p class="title">{{ media.title }}</p>
        <div  v-html="media.data">
        </div>
        <p><b>TAGS:</b> {{ media.tags }}</p>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import contentService from '@/services/contentMedia'
import utils from '@/conf/utils';

const media = ref({updated: new Date(), data:'', title:''})
const route = useRoute()

onMounted(()=>{
    const id = route.params.id
    getMedia(id)
})

async function getMedia(id) {

    await contentService.getMediaById(id)
        .then((response) => {
            if (response.data) {
                media.value = response.data
            }
        }).catch((error) => {
            console.log('getMedia comp', error)
        })
}

</script>

<style>
.title {
    font-size: 3rem;
}
.ql-syntax{
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px;
}
</style>