<template>
    <h1 class="mt-3">Vídeos</h1>

    <div class="container mb-5">
        <div class="row">
            <div v-for="(item, index) in videos" :key="index" class="card" style="width: 17rem;">
                <iframe :src="getSrc(item.data)" title="item">
                </iframe>
                <div class="card-body">
                    <p class="card-text">
                        <router-link :to="'/video/' + item.contentMediaId" class="video-link">
                            <small class="card-text">{{ item.title }}</small>
                        </router-link>
                    </p>
                </div>
                <div>
                    <router-link :to="'/video/' + item.contentMediaId" class="video-link">
                        <small class="card-text">Assistir</small>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import contentService from '@/services/contentMedia'
import * as cheerio from 'cheerio';

const videos = ref([])
const VIDEO = 1

onMounted(() => {
    getVideos()
})

function getSrc(data) {
    const $ = cheerio.load(data)
    return $('iframe').prop('src')
}

async function getVideos() {
    await contentService.getAll(VIDEO)
        .then((response) => {
            if (response.data) {
                videos.value = []
                response.data.forEach(element => {
                    videos.value.push(element)
                });
            }
        }).catch((error) => {
            console.log('AllVideos', error)
        })
}
</script>

<style scoped>
.video-link {
    text-decoration: none;
    color: #044fa5
}

.video-link:hover {
    color: #04224454
}
</style>