<template>
    <div class="container">
        <div class="row mt-5">
            <h4><b>{{ subject.name }}</b></h4>
            <div class="mt-4">
                <p>{{ subject.goal }}</p>
                <h5><b>Ementa</b></h5>
                <ul>
                    <li v-for="(item, index) in subject.ementa" :key="index">
                        {{ item }}
                    </li>
                </ul>
                <h5><b>Documentos</b></h5>
                <ul>
                    <li v-for="(item, index) in subject.materials" :key="index">
                        <a :href="item.link" target="_blank">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </div>   
    </div>
</template>
<script setup>
import {ref } from 'vue';
import { useRoute } from 'vue-router';
import subjectService from '@/services/subjectService.js'

const subject = ref()
const route = useRoute();  
const paramId = ref(route.params.subjectId)

subject.value = subjectService.getSubjects().find(s=> s.id == paramId.value)

</script>
<style scoped>
    
</style>