export default {
    getSubjects(){
        return [
            {
                id: 'programacao-para-web',
                name: 'Programação para Web',
                goal: 'O objetivo da disciplina é estimular o aluno a explorar a programação para web a partir de ' + 
                'conceitos básicos, porém, relevantes para o aprendizado das tecnologias utilizadas. Para tanto, ' +
                'será apresentado no curso as ferramentas básicas para iniciar sua carreira de desenvolvedor web.',
                ementa: [
                    "Introdução a Web", 
                    "HTLM e CSS",
                    "Bootstrap",
                    "Javascript",
                    "VueJs",
                    "Introdução ao NodeJS",
                    "Express"
                ],
                materials: [
                    {
                        title: 'Introdução a Web',
                        link: 'https://gm2.dev.br/download/subjects/www/1.introducao-web.pdf'
                    }
                ]
             },
             {
                id: 'dotnet',
                name: 'Desenvoldimento de Sistemas de Informação, enfoque dotnet',
                goal: 'O objetivo da disciplina é apresentar ao aluno os principais pilales tecnológicos do "Mundo Dotnet". Será ' +
                'apresentado, de forma prática e teórica, fremeworks e bibliotecas mais utilizadas pelos desenvolvedores dotnet.',
                ementa: [
                    "Introdução a Dotnet", 
                    "Linguagem de programação C#",
                    "Webservices e APIs com WebAPI Framework",
                    "Entity Framework ORM",
                    ".NET MAUI"
                ],
                materials: [
                    {
                        title: 'Introdução ao dotnet',
                        link: 'https://gm2.dev.br/download/subjects/dotnet/1.introducao-ao-dotnet.pdf'
                    }
                ]
             },
          ]
    }
}