<template>
  <div>
    <div class="row mt-4">
      <h3>Vídeos</h3>
      <hr />
    </div>
    <div class="row">
      <div class="content">
        <div id="item">
          <h3 class="mt-2">
            <p>{{ media.title }}</p>
          </h3>
          <div v-html="media.data">

          </div>           
          <p><b>TAGS:</b> {{ media.tags }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import contentService from '@/services/contentMedia'

const media = ref({ updated: new Date(), data: '', title: '', src:'' })
const route = useRoute()

onMounted(() => {
  const id = route.params.id
  getMedia(id)
})

async function getMedia(id) {
  await contentService.getMediaById(id)
    .then((response) => {
      if (response.data) {
        media.value = response.data
      }
    }).catch((error) => {
      console.log('getMedia comp', error)
    })
}

</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
}
</style>
